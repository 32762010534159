<template>
    <div id="edgeEndHistory"  v-loading="loading" :element-loading-text="$t('alarm.a0')">
         <div class="header" >
        <my-header-title> 边缘终端设备记录 </my-header-title>
        <div class="header-box" style="padding:5px;">
                <div style="display:flex;">
                        <!-- <a-input  v-model="processor" placeholder="" title="" />	 -->
                        
                        <!-- <a-input  v-model="floorId" placeholder="" title="" /> -->
                       
                        <!-- <a-input  v-model="detectAreaId" placeholder="" title="" /> -->
                        
                        <!-- <a-input  v-model="entranceId" placeholder="" title="" /> -->
                        <!-- <a-input  v-model="signalName" placeholder="" title="" />
                        <a-input  v-model="equipmentName" placeholder="" title="" /> -->
                    
                    <!-- <div class="header-column">
                        <a-input  v-model="device" placeholder="终端序列号" title="" />
                    </div>
                    <div class="header-column">
                        <a-input  v-model="name" placeholder="终端名称" title="" />
                    </div> -->
                    <div class="header-column">
                         <a-input  v-model="floorName" placeholder="物理区域名称" title="" />
                    </div>
                    <div class="header-column">
                         <a-input  v-model="detectAreaName" placeholder="检测区域名称" title="" />
                    </div>

                    <div class="header-column">
                        <!-- <span>{{ $t("energy.enedata343") }}</span> -->
                        <a-select v-model="category" style="width: 200px;" 
                            placeholder="请选择记录类型"   title="记录类型">
                            <a-select-option :title="itme.name"
                                v-for="(itme, index) in categories"
                                :key="index"
                                :value="itme.id"
                                
                                >
                                {{ itme.name }}
                            </a-select-option>
                        </a-select> 
                    </div>
                    <div class="header-column">
                        <!-- <span>{{ $t("energy.enedata343") }}</span> -->
                        <a-select v-model="eventType" style="width: 200px;" 
                            placeholder="请选择事件类型"   title="事件类型">
                            <a-select-option :title="itme.name"
                                v-for="(itme, index) in events"
                                :key="index"
                                :value="itme.code"
                                
                                >
                                {{ itme.name }}
                            </a-select-option>
                        </a-select> 
                    </div>

                    <div class="header-column">
                        <!--  <span>{{ $t("energy.enedata339") }}</span> -->
                        <a-date-picker
                          v-model="dateFrom"
                          :disabled-date="disabledStartDate"
                          :allowClear="false"
                          format="YYYY/MM/DD"
                          @openChange="handleStartOpenChange"
                          @change="handleCalendarChange"
                          :title="$t('energy.enedata339')"
                        />
                    </div>

                    <div class="header-column">
                        <!-- <span>{{ $t("energy.enedata340") }}</span> -->
                        <a-date-picker
                          v-model="dateTo"
                          :disabled-date="disabledEndDate"
                          :allowClear="false"
                          format="YYYY/MM/DD"
                          :open="endOpen"
                          @openChange="handleEndOpenChange"
                          :title="$t('energy.enedata340')"
                        />
                    </div>    
                </div>
                <a-button type="primary" @click="search">{{ $t("energy.enedata006") }}</a-button>
              </div>
         </div>
         <div class="main" ref="main">
            <!-- <my-tabletitle>{{ $t("energy.enedata335") }}</my-tabletitle> -->
            <a-table
              :columns="columns"
              :dataSource="data"
              :scroll="size"
              :pagination="pagination" :rowKey="(record) => record.id">
                <template slot="image" slot-scope="text, record " v-if="record.image && record.image!==''">
                    <a-button icon="picture" type="primary" @click="() => openPicture(record)" title="查看图片"></a-button>
                </template>
            </a-table>
        </div>
        <a-modal v-model="visible" :width="720" :title="irecord.equipmentName" on-ok="handleOk">
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    关闭
                </a-button>
            </template>
            <!-- <pre>{{ JSON.stringify(irecord) }}</pre> -->
            <div class="item" v-if="irecord.equipmentName && irecord.equipmentName!==''">
               <span>{{ irecord.equipmentName  }}</span> 
               <span>事件：{{ irecord.eventZh  }}</span> 
               <span>时间：{{ irecord.dateTime  }}</span> 
            </div>
            <img :src="irecord.image" v-if="irecord.image && irecord.image!==''" style="width:100%;" alt=""/>
            <a-empty v-else></a-empty>
        </a-modal>
    </div>
            
</template>
<script>
import {getEdgeHistory} from "../../../api/end.js";
import headerTitle from "../../../components/Title/headerTitle";

import moment from "moment";
export default {
     data() {
        return {
            loading: false,
            data:[],
            pageNum:0,
            pageSize:100,
            dateFrom:moment(),
            dateTo:moment(),
            category:0,
            eventType:'',
			processor		:'',
            device          :'',
            name            :'',
            floorId         :'',
            floorName       :'',
            detectAreaId    :'',
            detectAreaName  :'',
            entranceId      :'',
            signalName      :'',
            equipmentName      :'',
            endOpen:null,
            size: { x: 1000, y: 240 },
            pagination:{
                total: 0, //数据总数
                pageSize: 100, //每页中显示10条数据
                showTotal: (total) => {
                    let msg = this.$t("energy.enedata195");
                    msg = msg.replace('${total}',total);
                    return msg;
                    // `共 ${total} 条数据`
                }, //分页中显示总的数据
                showQuickJumper: true, //是否可以快速跳转至某页
                defaultCurrent: 1, //默认当前页面数
                hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
                onShowSizeChange: (current, pageSize) => {
                    this.pagination.defaultCurrent = current;
                    this.pagination.pageSize = pageSize;
                    this.pageNum = current-1;
                    this.pageSize = pageSize;
                    this.getHistory();
                },
                // 改变每页数量时更新显示
                onChange: (current, size) => {
                    this.pagination.defaultCurrent = current;
                    this.pagination.pageSize = size;
                    this.pageNum = current-1;
                    this.pageSize = size;
                    this.getHistory();
                },
            },
            
            columns:[
                {dataIndex: 'no',              title: "No."	          ,width:80,
                    customRender: (text, record, index) =>
                    `${
                    (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
                    index +
                    1
                    }`
                },
                {dataIndex: 'dateTime',             title: '时间',              width:200},
                {dataIndex: 'category',             title: '类型',              width:150,
                    customRender: (text, record, index) =>
                    `${
                        (this.getCategoryName(record)) 
                    }`,
                },
                {dataIndex: 'device',               title: '终端序列号',                width:180},
                {dataIndex: 'name',                 title: '终端名称',                  width:180},
                {dataIndex: 'processor',            title: '终端型号',             width:150},
                {dataIndex: 'floorId',              title: '物理区域ID',               width:150},
                {dataIndex: 'floorName',            title: '物理区域名称',             width:150},
                {dataIndex: 'cameraSerialId',       title: '摄像头标识',        width:150},
                {dataIndex: 'entranceId',           title: '出入口区域ID',            width:150},
                {dataIndex: 'detectAreaId',         title: '检测区域ID',          width:150},
                {dataIndex: 'detectAreaName',       title: '检测区域名称',        width:150},

                //event
                {dataIndex: 'eventEn',              title: '事件类型代码',               width:150},
                {dataIndex: 'eventZh',              title: '事件类型',               width:150},             
                // {dataIndex: 'event',                title: '事件',                 width:150},
                {dataIndex: 'cardNo',               title: 'trackerId',                width:150},
                {dataIndex: 'cross',                title: '跨界动作',                 width:150,
                    customRender: (text, record, index) =>
                    `${
                        (record.eventEn ==='Transboundary'?(record.cross===0?'进':'出'):'') //'停止''启用'
                    }`
                },
                {dataIndex: 'inTime',               title: '进入时间',                width:200},
                {dataIndex: 'outTime',              title: '离开时间',               width:200},
                {dataIndex: 'cardId',               title: '人员ID号',                width:150},
                {dataIndex: 'analogEventValue',		title: '人员聚集面积',		width:150},
                //event

                //EntranceRegular 
                {dataIndex: 'inCount',              title: '进入电梯人数',               width:150},
                {dataIndex: 'outCount',             title: '离开电梯人数',              width:150},

                // Detect
                {dataIndex: 'eBike',                title: '电动车数',                 width:150},
                {dataIndex: 'pet',                  title: '宠物数',                   width:150},
                {dataIndex: 'wheelChair',           title: '轮椅数',            width:150},
                //EntranceRegular 

                {dataIndex: 'manCount',             title: '人数',              width:150},
                {dataIndex: 'waitingPeople',        title: '候梯人数',         width:150},
                {dataIndex: 'waitingWheelChair',    title: '等候轮椅数',     width:150},
                // Detect
                
                {dataIndex: 'occupyRate',           title: '占有率',            width:150},
                {dataIndex: 'crowdRate',            title: '拥挤度',             width:150},


                // {dataIndex: 'liftConf',             title: '电梯信息',              width:150},
                // {dataIndex: 'visibleStatus',        title: '可视化状态',         width:150},
                // {dataIndex: 'direction',            title: '方向',             width:150}, 


                {dataIndex: 'image',                title: '图片',                 width:150,scopedSlots: { customRender: "image" },},
                // {dataIndex: 'base64',               title: '图片',                width:150},

                // {dataIndex: 'eventMessage',         title: 'eventMessage',          width:150},
                // {dataIndex: 'eventName',            title: 'eventName',             width:150},
                // {dataIndex: 'eventText',            title: 'eventText',             width:150},
                // {dataIndex: 'eventValue',           title: 'eventValue',            width:150},
                // {dataIndex: 'equipmentName',        title: 'equipmentName',         width:150},
                
                // {dataIndex: 'analogEventValue',		title: 'analogEventValue',		width:150},
                // {dataIndex: 'bodyTemperature',      title: 'bodyTemperature',       width:150},
                // 
                
                // {dataIndex: 'clientId',             title: 'clientId',              width:150},
                // {dataIndex: 'clientName',           title: 'clientName',            width:150},
                // {dataIndex: 'deviceIdentifier',     title: 'deviceIdentifier',      width:150},

                // {dataIndex: 'flcAlarmHistoryType',  title: 'flcAlarmHistoryType',   width:150},
                // {dataIndex: 'fmcAlarmHistoryType',  title: 'fmcAlarmHistoryType',   width:150},
                // {dataIndex: 'id',                   title: 'id',                    width:150},
                // {dataIndex: 'objectIdentifier',     title: 'objectIdentifier',      width:150},
                // {dataIndex: 'personName',           title: 'personName',            width:150},
                // {dataIndex: 'personNodePath',       title: 'personNodePath',        width:150},
                
                // {dataIndex: 'signalName',           title: 'signalName',            width:150},
                // {dataIndex: 'signalNodePath',       title: 'signalNodePath',        width:150},
                // {dataIndex: 'siteGrId',             title: 'siteGrId',              width:150},
                // {dataIndex: 'siteGrName',           title: 'siteGrName',            width:150},
                // {dataIndex: 'siteId',               title: 'siteId',                width:150},
                // {dataIndex: 'siteName',             title: 'siteName',              width:150},
                // {dataIndex: 'smartGatewayId',       title: 'smartGatewayId',        width:150},
                // {dataIndex: 'tenantId',             title: 'tenantId',              width:150},
                // {dataIndex: 'tenantName',           title: 'tenantName',            width:150},
                // {dataIndex: 'unsignedEventValue',   title: 'unsignedEventValue',    width:150},
                // {dataIndex: 'userId',               title: 'userId',                width:150},
                // {dataIndex: 'userName',             title: 'userName',              width:150},
                // {dataIndex: 'userNodePath',         title: 'userNodePath',          width:150},
            ],
            startDate: undefined,
            stime:' 00:00:00',
            etime:' 23:59:59',
            categories:[
                {id:0, code:''		,name:'全部'},
                {id:1, code:'floors/config'		,name:'物理区域配置通知'},
                {id:2, code:'lift/config'		,name:'电梯配置通知'},
                {id:3, code:'camera/config'		,name:'摄像头配置通知'},
                {id:4, code:'info/req'			,name:'向终端请求信息'},
                {id:5, code:'info/res'			,name:'终端回复消息'},
                {id:6, code:'detect/regular'	,name:'检测区域定时通知'},
                {id:7, code:'detect/event'		,name:'检测区域实时事件通知'},
                {id:8, code:'entrance/regular'	,name:'出入口区域定时通知'},
                {id:9, code:'entrance/event'	,name:'出入口区域实时事件通知'},
                {id:10,code:'floor/regular'		,name:'物理区域定时通知'},
                {id:11,code:'terminal/status'	,name:'终端状态定时通知'}
            ],
            events:[
                {code:'',name:'全部'},
                 {code:'Transboundary',name:'跨界'},
                {code:'Stay',name:'滞留'},
                {code:'Wait time',name:'候梯'},
                {code:'Gather',name:'聚集'},
                {code:'Someone',name:'梯上有（无）人'},
                {code:'Jam',name:'拥堵'},
                {code:'Remnants',name:'遗留物'},
                {code:'BabyCarriage',name:'婴儿车'},
                {code:'WheelChair',name:'轮椅'},
                {code:'BulkyItems',name:'携带大件物品'},
                {code:'OutOfHandrail',name:'头手伸出扶手带'},
                {code:'PassengerFell',name:'乘客摔倒'},
                {code:'PassengerRetrograde',name:'乘客逆行'},
                {code:'MissingRungs',name:'梯级缺失'},
                {code:'MissingFloorPanels',name:'脚踏板缺失'},
            ],
            visible:false,
            irecord:{equipmentName:'',image:'',dateTime:'',eventZh:''},
        };
    },
    components: {
        'my-header-title':headerTitle,
    },

    activated(){
        // this.search();
        this.category=0,
        this.eventType='',
        this.data = [];
        this.pageNum = 0;
        this.pagination.total = 0;
        this.tableSize();
        window.addEventListener("resize", this.tableSize);
    },
    mounted() {
    
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.tableSize);
    },
    methods: {
        handleCancel(e) {
            this.visible = false;
            this.img = '';
            this.irecord = {equipmentName:'',image:'',dateTime:'',eventZh:''};
        },
        openPicture(record){
            this.img = record.image;
            this.visible = true;
            let {equipmentName,image,dateTime,eventZh} = record;
            this.irecord = {equipmentName,image,dateTime,eventZh};
        },
        search(){
                this.pageNum = 0;
                this.pageSize = this.pagination.pageSize;
                this.getHistory();
        },
        getHistory(){
            let end = new moment(this.dateTo);
            let end2 = end.add(1,'days');

            let params =  {
                action:1,
                clientId: this.$store.getters.clientId,
                sitegrId: this.$store.getters.sitegrId,
                siteId: this.$route.query.id,
                tenantId: 0,
                pageNum:this.pageNum,
                pageSize:this.pageSize,
                startDate: this.dateFrom.format("YYYY-MM-DD") + this.stime,
                endDate:  end2.format("YYYY-MM-DD") + this.stime,
                category		:this.category>0?this.category:null,
				eventType		:this.eventType,
				processor		:this.processor		,
				device          :this.device          ,
				name            :this.name            ,
				floorId         :this.floorId         ,
				floorName       :this.floorName       ,
				detectAreaId    :this.detectAreaId    ,
				detectAreaName  :this.detectAreaName  ,
				entranceId      :this.entranceId      ,
				signalName      :this.signalName      ,
				equipmentName   :this.equipmentName   ,
            };
            console.log("GetEdgeHistory Params",params);
            this.loading = true;
            getEdgeHistory(params)
            .then((res) => {
                console.log("GetEdgeHistory Result",res);
                if(res.errorCode == '00'){
                    this.data = res.data.data;
                    this.pagination.total = res.data.totalCount;
                } 
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
                console.log(err);
            });
        },
        // 表格自适应方法
        tableSize() {
            let height = this.$refs.main.clientHeight;
            let tableHeight = height - 100;
            this.size.y = tableHeight;
        },
        // 初始日期/时间选择变化回调
        handleCalendarChange(value, mode) {
            this.startDate = moment(value._d).format("YYYY/MM/DD");
            this.dateTo = null;
        },
            // 初始日期/时间的日期不可选范围
        disabledStartDate(startValue) {
            const endValue = this.dateTo;
            if (!startValue || !endValue) {
            return false;
            }
            return (
            startValue.valueOf() > endValue.valueOf() ||
            startValue >= moment().endOf()
            );
        },
        // 终止日期/时间的日期不可选范围
        disabledEndDate(endValue) {
            const startValue = this.dateFrom;
            let startDate = this.startDate;
            if (!endValue || !startValue) {
            return false;
            }
            return (
            startValue.valueOf() >= endValue.valueOf() ||
            endValue >= moment().endOf() ||
            endValue >= moment(new Date(startDate)).add(1, "months")
            );
        },
        // 初始日期/时间选择弹出日历和关闭日历的回调
        handleStartOpenChange(open) {
            if (!open) {
            this.endOpen = true;
            }
        },
        // 终止日期/时间选择弹出日历和关闭日历的回调
        handleEndOpenChange(open) {
            this.endOpen = open;
        },
        getCategoryName(record){
            let name = '';
            this.categories.forEach(element => {
                if(element.id === record.category){
                    name = element.name;
                }
            });
            return name;
        }
    }
}
</script>
<style scoped>
#edgeEndHistory {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.header {
  min-width: 850px;
  padding: 0 20px 10px;
  overflow: hidden;
}
.header-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-box button{
  text-align: right;
}
.header-condition {
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 25px;
}

.header-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 5px;
}
.header-column span {
  font-size: 14px;
}
.main {
  height: calc(100% - 140px);
  width: 100%;
  min-width: 670px;
  padding: 0px 20px;
  overflow: hidden;
}
.item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 5px;
}
</style>
